import React from "react";
import { PageWrapper } from "~components/Core";
// import HeroSection from '~sections/project/Hero'
import Form from '../sections/form/form'





export default function Project() {
    return (
        <PageWrapper innerPage={true}>



            <Form />

        </PageWrapper>
    )
}
